'use client';

import { useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';

//* Helpers
import { config } from '@/helpers';

//* Components
import Page from '@/components/global/Page';
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';

const NotFound = () => {
	//! Next Navigation
	const router = useRouter();
	const pathname = usePathname();

	useEffect(() => {
		if (pathname !== '/not-found/') {
			router.push('/not-found/');
		}
	}, [router, pathname]);

	return (
		<Page className={'not-found'}>
			<Container row>
				<div className='404-empty-col col-3 col-t-2 col-ts-12' />

				<div className='not-found-inner col-6 col-t-8 col-ts-12'>
					<Text
						className='h1 primary-color1 workSans-regular uppercase'
						text='404Title'
					/>

					<Text
						className='p p1 primary-color1 workSans-regular uppercase not-found-description'
						text='404Desc'
					/>

					<Button
						text='goToHomepage'
						className='default primary'
						url={config.routes.home.path}
					/>
				</div>

				<div className='404-empty-col col-3 col-t-2 col-ts-12' />
			</Container>
		</Page>
	);
};

export default NotFound;
